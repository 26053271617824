<template>
  <div class="report">
    <van-nav-bar
      title="Report"
      fixed
      placeholder
    >
      <template #right>
        <div class="icons-grid">
          <van-icon
            class-prefix="nutr-lopro-icons"
            name="share"
            size="20"
            @click="openSharePopup"
          />
          <van-icon
            class-prefix="nutr-lopro-icons"
            name="print"
            size="30"
            @click="handlePrintPage"
          />
        </div>
      </template>
    </van-nav-bar>
    <van-popup
      v-model="showSharePopup"
      position="top"
      class="share-popup"
    >
      <div class="share-buttons">
        <share-button
          :title="$t('reporting.share.copy.title')"
          :description="$t('reporting.share.copy.description')"
          :class="{'is-copied': copied}"
          @click.native="handleCopyLink"
        />
        <share-button
          :title="$t('reporting.share.reset.title')"
          :description="$t('reporting.share.reset.description')"
          icon="reset"
          @click.native="handleRenewLink"
        />
      </div>
    </van-popup>
    <report-charts
      :diagnosis="diagnosis"
      :diagnosis-abbreviation="diagnosisAbbreviation"
      :metabolism-spec="metabolismSpec"
      :get-saved-dayplans="getSavedDayplans"
      :user-profile="userProfile"
    />
    <div class="branding__container">
      <branding />
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';

import Diagnosis from '@/models/diagnosis';
import Dayplan from '@/models/dayplan';
import SharedReport from '@/models/user-report';
import UserProfile from '@/models/user-profile';
import Branding from '@/components/Branding';
import ReportCharts from '@/components/ReportCharts';
import ShareButton from '@/components/ShareButton';
import { auth, analytics } from '@/firebase';
import { getAbbreviation, getNutritionUnit } from '@/helper';

export default {
  components: {
    Branding,
    ReportCharts,
    ShareButton,
  },
  data() {
    return {
      copied: false,
      diagnosis: null,
      diagnosisAbbreviation: '',
      metabolismSpec: '',
      showSharePopup: false,
      userProfile: null,
    };
  },
  async created() {
    this.shareReport = new SharedReport(this.$router);
    this.diagnosis = new Diagnosis(this.$store.getters['user/diagnosisName']);
    this.userProfile = await new UserProfile(auth.currentUser.uid).get();
    this.nutritionLimits = this.$store.getters['user/nutritionLimits'];
    this.metabolismSpec = `${this.nutritionLimits[this.diagnosis.nutritionKey]} ${getNutritionUnit(this.diagnosis.nutritionKey)}`;
    this.diagnosisAbbreviation = getAbbreviation(this.diagnosis.nutritionKey) || this.$i18n.t('protein');
  },

  methods: {
    handlePrintPage() {
      analytics.logEvent('report_print');
      window.print();
    },

    openSharePopup() {
      this.showSharePopup = true;
    },

    async handleCopyLink() {
      const link = await this.shareReport.getLink();
      this.shareReport.copyLink(link);
      this.copied = true;

      analytics.logEvent('report_share_link');

      setTimeout(() => {
        this.copied = false;
        this.showSharePopup = false;
      }, 2000);
    },

    handleRenewLink() {
      Dialog.confirm({
        title: this.$i18n.t('reporting.share.reset.title'),
        message: this.$i18n.t('reporting.share.reset.warning'),
      })
        .then(async () => {
          await this.shareReport.renewLink();
          analytics.logEvent('report_share_reset');

          this.handleCopyLink();
        })
        .catch(() => {});
    },

    async getSavedDayplans() {
      const result = await Dayplan.getSavedDates().get();
      const dates = [];

      result.docs.forEach((date) => {
        dates.push({ id: date.id, nutritionSums: date.data().nutritionSums });
      });

      return dates;
    },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.icons-grid {
  align-items: center;
  display: grid;
  gap: config.$spacing-xs;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}

.share-popup {
  background: none;
  width: 90%;

  &.van-popup--top {
    bottom: initial;
    left: 50%;
    top: 20%;
    transform: translateX(-50%);
  }
}

.share-buttons {
  display: grid;
  gap: config.$spacing-xs;
  margin: 0 auto;
  max-width: 360px;
  width: 100%;
}
</style>
