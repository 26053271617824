<template>
  <button class="share-button">
    <div class="share-button__content">
      <div class="share-button__title">
        {{ title }}
      </div>
      <p
        v-if="description"
        class="share-button__description"
      >
        {{ description }}
      </p>
    </div>
    <div class="share-button__icon">
      <van-icon
        class-prefix="nutr-lopro-icons"
        :name="icon"
        :size="size"
      />
    </div>
  </button>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    description: { type: String, default: null },
    icon: { type: String, default: 'link' },
    size: { type: Number, default: 30 },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.share-button {
  align-items: center;
  appeareance: none;
  background: config.$color-white;
  border: 6px solid config.$color-white;
  border-radius: 5px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 5fr 1fr;
  justify-content: center;
  min-height: 4rem;
  padding: config.$spacing-xs config.$spacing;
  transition: border-color .25s ease-in-out;
  width: 100%;

  &.is-copied {
    border-color: config.$brand-secondary;
  }

  &__icon {
    transition: all .25s ease-in-out;

    .is-copied & {
      color: config.$brand-secondary;
      transform: rotate(360deg);
    }
  }

  &__content {
    text-align: left;
  }

  &__title {
    font-weight: 700;
  }

  &__description {
    font-size: config.$font-size-sm;
    margin: 0;
  }
}
</style>
